<template>
  <div>
    <!-- calendar -->
    <v-row class="mb-3 pa-3 pr-5 ap-light-grey align-center rounded" no-gutters>
      <DateRangePicker @change="$emit('change', $event)" />
      <v-spacer />
      <div class="text-h6 ap-dark-gray--text font-weight-bold text-center">
        7 Duplicate Sets
      </div>
    </v-row>
  </div>
</template>

<script>
import DateRangePicker from '@/components/shared/DateRangePicker'

export default {
  components: {
    DateRangePicker,
  },
}
</script>
