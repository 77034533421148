<template>
  <v-card class="completed-tasks pa-3">
    <!-- Heading -->
    <CardHeader title="Completed Tasks" class="pa-0" />
    <!-- progress -->
    <v-progress-linear
      v-if="userIsSalesRep"
      class="mb-3"
      value="15"
      color="primary"
    />
    <!-- Tasks Filter and Graph -->
    <div v-else class="graph-container px-4">
      <CompletedTasksFilterDropdown />
      <CompletedTasksGraph :stats="resolvedStats" />
    </div>
    <!-- Calendar and Duplicates Number -->
    <CompletedTasksDateFilter @change="onDateChange" />
    <!-- search and export -->
    <div class="d-flex">
      <v-text-field
        v-model="search"
        class="mb-3 mr-3"
        placeholder="Search"
        append-icon="$mdi-magnify"
        hide-details
        outlined
        dense
      />
      <CompletedTasksExportButton
        @exportStarted="showExportMsg = true"
        @exportFinished="showExportMsg = false"
      />
    </div>
    <!-- Export Msg -->
    <v-expand-transition hide-on-leave leave-absolute>
      <div v-if="showExportMsg" class="text-caption">
        <div class="ap-yellow-10 rounded">
          <div class="pa-2 d-flex align-center">
            <div class="ap-dark-gray--text">
              The file will download in a few minutes.<br />Don’t close your
              browser until the download is complete.
            </div>
            <v-btn
              class="ml-auto"
              text
              icon
              small
              @click="showExportMsg = false"
            >
              <v-icon size="20"> $mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="pb-3" />
      </div>
    </v-expand-transition>
    <!-- Completed Tasks Table -->
    <CompletedTasksTable
      :data="filteredDupeSets"
      :fields="mergedDupeSetsFields"
    />
  </v-card>
</template>

<script>
import CompletedTasksDateFilter from './CompletedTasksDateFilter'
import CompletedTasksTable from './CompletedTasksTable'
import CompletedTasksGraph from './CompletedTasksGraph'
import CompletedTasksFilterDropdown from './CompletedTasksFilterDropdown'
import CompletedTasksExportButton from './CompletedTasksExportButton'
import CardHeader from '@/components/shared/CardHeader'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    CardHeader,
    CompletedTasksDateFilter,
    CompletedTasksTable,
    CompletedTasksGraph,
    CompletedTasksFilterDropdown,
    CompletedTasksExportButton,
  },
  data() {
    return {
      search: '',
      showExportMsg: false,
    }
  },
  computed: {
    ...mapState('dashboard', [
      'resolvedStats',
      'mergedDupeSets',
      'mergedDupeSetsFields',
    ]),
    ...mapGetters('auth', ['userIsSalesRep']),
    filteredDupeSets() {
      if (!this.search) return this.mergedDupeSets

      const search = this.search.toLowerCase()

      return this.mergedDupeSets.filter(
        (item) => item.name.toLowerCase().indexOf(search) !== -1
      )
    },
  },
  mounted() {
    this.fetchData({
      start: new Date(),
      end: new Date(),
    })
  },
  methods: {
    ...mapActions('dashboard', ['getResolvedStats', 'getMergedDupeSets']),
    onDateChange(payload) {
      this.fetchData({
        start: payload.startDate,
        end: payload.endDate,
      })
    },
    async fetchData({ start, end }) {
      this.isLoading = true

      await Promise.all([
        this.getResolvedStats({ start, end }),
        this.getMergedDupeSets({ start, end }),
      ])

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.graph-container {
  position: relative;
  margin-bottom: 24px;
}
</style>
