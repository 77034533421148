<template>
  <!-- filter button -->
  <v-menu offset-y :close-on-content-click="false">
    <template #activator="{ on }">
      <v-btn class="filter-button" icon v-on="on">
        <v-icon>$mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-5">
      <span class="ap-dark-gray--text font-weight-bold">Display tasks</span>
      <div
        v-for="(item, index) in FilterItems"
        :key="index"
        class="d-flex align-center"
      >
        <v-checkbox
          v-model="toDisplay"
          :value="item"
          :label="item"
          hide-details
        />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    toDisplay: [],
    FilterItems: ['Admin tasks', 'Automated tasks', 'Sales Rep. tasks'],
  }),
}
</script>

<style>
.filter-button {
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1;
}
</style>
