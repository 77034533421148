<template>
  <span>
    <div class="my-5">
      <v-row
        v-for="(prop, i) of expandedProps"
        :key="i"
        class="text-caption my-3"
        no-gutters
      >
        <span class="width-x">{{ prop.label }}</span>
        <span class="font-weight-black">{{ item[prop.name] }}</span>
      </v-row>
    </div>
    <v-divider class="mb-5" />
    <div class="text-caption mb-4">
      <template v-if="userIsAdmin">
        <div>
          <v-btn
            v-if="item.status !== 'processed'"
            class="elevation-0 px-3 text-none font-weight-normal text-caption"
            dark
            color="ap-dark-blue"
          >
            Undo Merging
          </v-btn>
          <v-btn
            class="elevation-0 mx-3 text-none font-weight-normal text-caption"
            outlined
            color="ap-dark-gray"
            @click="openMergeHistoryDialog"
          >
            Merge History
          </v-btn>

          <DupesGridMergeHistoryDialog
            v-model="showDialog"
            :dupe-sets="dialogDupeSets"
          />
        </div>
      </template>
      <template v-else>
        <div v-if="item.status === 'processed'">
          See a mistake?
          <span class="ap-blue--text cursor-pointer">Click here</span>
        </div>
        <div v-else>
          See a mistake?
          <span class="ap-blue--text cursor-pointer">Cancel here</span>
        </div>
      </template>
    </div>
  </span>
</template>

<script>
import DupesGridMergeHistoryDialog from '@/components/dupesgrid/DupesGridMergeHistoryDialog.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    DupesGridMergeHistoryDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    expandedProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // merge history dialog
      showDialog: false,
      dialogDupeSets: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['userIsAdmin']),
  },
  methods: {
    // CLEAN UP: use the API Layer for this when it is supose to work
    openMergeHistoryDialog() {
      const item = Object.assign({}, this.item, {
        owner: 'John Smith',
        source: this.item.source,
        name: this.item.name,
        id: this.item.accountId,
        created: this.item.mergeDate,
        owner_id: this.item.accountId,
        assignee_names: ['John Smith'],
        final_record: {
          Name: this.item.source,
          BillingStreet: this.item.billingStreet,
          BillingState: this.item.billingState,
          CreatedDate: this.item.mergeDate,
          LastModifiedDate: this.item.mergeDate,
          OwnerId: this.item.accountId,
          RecordType: {
            Name: this.item.name,
          },
          Id: this.item.accountId,
        },
      })

      this.dialogDupeSets = [item]
      this.showDialog = true
    },
  },
}
</script>
