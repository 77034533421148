var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"my-table",attrs:{"headers":_vm.headersList,"items":_vm.data,"item-key":"accountId","show-expand":"","mobile-breakpoint":321,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ap-red--text"},[_vm._v(_vm._s(item))])]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium text-caption"},[_vm._v(_vm._s(item.source))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text text-caption"},[_vm._v(_vm._s(item.name))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ap-dark-gray--text text-caption"},[_vm._v(_vm._s(item.mergedAtDate))])]}},{key:"item.id",fn:function(){return [_c('div',[_c('v-icon',{attrs:{"size":"20","color":"success"}},[_vm._v(" $mdi-clipboard-check-outline ")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td'),_c('td',{attrs:{"colspan":headers.length - 1}},[_c('CompletedTasksTableExpandedItem',{attrs:{"item":item,"expanded-props":_vm.fields}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }