import { render, staticRenderFns } from "./CompletedTasks.vue?vue&type=template&id=5222f5f8&scoped=true&"
import script from "./CompletedTasks.vue?vue&type=script&lang=js&"
export * from "./CompletedTasks.vue?vue&type=script&lang=js&"
import style0 from "./CompletedTasks.vue?vue&type=style&index=0&id=5222f5f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5222f5f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VExpandTransition,VIcon,VProgressLinear,VTextField})
