<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <v-btn class="px-0" outlined height="40" color="ap-dark-gray" v-on="on">
        <div class="px-3 font-weight-bold text-caption">export</div>
        <div
          class="d-flex align-center justify-center px-2"
          :style="{
            borderLeft: `1px solid ${$vuetify.theme.themes.light['ap-dark-gray']}`,
          }"
        >
          <v-icon size="22"> $mdi-chevron-down </v-icon>
        </div>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="onExport"
      >
        <v-list-item-title class="text-caption">
          {{ item }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    items: ['Export XLSX', 'Export CSV'],
  }),
  methods: {
    onExport() {
      this.$emit('exportStarted')
      // simulate time taken for export to finish
      setTimeout(() => {
        this.$emit('exportFinished')
      }, 10000)
    },
  },
}
</script>
