<template>
  <v-data-table
    class="my-table"
    :headers="headersList"
    :items="data"
    item-key="accountId"
    show-expand
    :mobile-breakpoint="321"
    hide-default-footer
    disable-sort
    dense
  >
    <template #header="{ item }">
      <span class="ap-red--text">{{ item }}</span>
    </template>
    <template #[`item.source`]="{ item }">
      <span class="font-weight-medium text-caption">{{ item.source }}</span>
    </template>
    <template #[`item.name`]="{ item }">
      <span class="primary--text text-caption">{{ item.name }}</span>
    </template>
    <template #[`item.date`]="{ item }">
      <span class="ap-dark-gray--text text-caption">{{
        item.mergedAtDate
      }}</span>
    </template>
    <template #[`item.id`]>
      <div>
        <v-icon size="20" color="success">
          $mdi-clipboard-check-outline
        </v-icon>
      </div>
    </template>
    <template #expanded-item="{ headers, item }">
      <td />
      <td :colspan="headers.length - 1">
        <CompletedTasksTableExpandedItem
          :item="item"
          :expanded-props="fields"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import CompletedTasksTableExpandedItem from './CompletedTasksTableExpandedItem'

export default {
  components: {
    CompletedTasksTableExpandedItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headersList: [
      { text: '', value: 'data-table-expand' },
      {
        text: 'Source',
        align: 'start',
        value: 'source',
        class: 'font-weight-regular',
      },
      {
        text: 'Name',
        value: 'name',
        class: 'font-weight-regular',
      },
      {
        text: 'Date',
        value: 'date',
        class: 'font-weight-regular',
      },
      {
        text: '',
        value: 'id',
        class: 'font-weight-regular',
      },
    ],
  }),
}
</script>

<style scoped lang="scss">
.my-table {
  .v-data-table-header {
    background-color: var(--v-ap-light-grey-base);

    span {
      color: var(--v-ap-dark-gray-base);
    }
  }
  .v-data-table__expanded__content {
    box-shadow: unset !important;
  }
  .width-x {
    width: 100px;
  }
}

.v-data-table td,
.v-data-table th {
  padding: 0 5px !important;
}
</style>
